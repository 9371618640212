const Page404 = () => {
    return (
        <div className="wrapper bg-light h-100 d-flex align-items-center" style={{ minHeight: '75vh' }}>
            <div className="container text-center">
                <h1 className=" text-primary display-1">
                    404 Not Found
                </h1>
                <p className="lead fs-lg mb-6">
                    An unexpected condition was encountered. Our service team
                    has been dispatched to bring it back online.
                </p>
                <div className='flex'>
                    {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                    <a className="hover" href="/">Back to Home page</a>
                </div>
            </div>
        </div>
    );
};

export default Page404;
